<template>
  <div>
    <main>
      <section id="what">
        <b-container>
          <b-row class="mx-0 align-items-center">
            <b-col
              lg="8"
            >
              <h1
                v-html="$t('sfs.section.title')"
              ></h1>
              <h2
                class="mt-5"
                v-html="$t('meta.lead')"
              ></h2>
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-center"
              lg="4"
            >
              <img
                alt=""
                class="m-4"
                :src="`${$t('baseurl')}img/sepia.png`"
              />
            </b-col>
          </b-row>
        </b-container>
      </section>

      <section id="roadmap">
        <b-container>
          <b-row class="mx-0 align-items-center">
            <b-col
              lg="10"
            >
              <div v-html="$t('roadmap')"></div>
            </b-col>
          </b-row>
        </b-container>
      </section>

      <who />

      <sfs
        :offpay="false"
        :oto="false"
      />
    </main>
  </div>
</template>

<script>
import Who from '../../commons/components/hos/Who.vue';
import Sfs from '../../commons/components/sfs/Main.vue';

export default {
  components: {
    Who,
    Sfs,
  },
};
</script>

<style lang="scss">
#home {
  --p-o1: #f2690d;
  --p-o2: #F2690D;
  --p-o3: #FFC99E;
  --p-o4: #FCE8DB;
  --p-o5: #fffaf6;

  --p-g1: #000;
  --p-g2: #585858;
  --p-g3: #e5e5e5;
  --p-g4: #e9ecef;
  --p-g5: #fff;

  background: var(--p-o5);

  h1, h2, h3 {
    background: none;
    color: var(--p-g1);
    font-family: Proza Libre,sans-serif;
    font-weight: 600;
    transform: none;
  }

  p>a,
  p>span>a,
  p>strong>a,
  li>a {
    color: #212529;
    font-weight: 700;
    border-bottom: 3px solid var(--p-o1);
    text-decoration: none;
    transition: color .1s;

    &:hover, &:focus {
      text-decoration: none;
      color: #485159;
    }
  }
}

#what {
  .container {
    padding: 10px 15px !important;
  }

  @media (min-width: 992px) {
    .container {
      padding: 40px 70px !important;
    }
  }
}

#what {
  @media (min-width: 576px) {
    padding-top: 3rem;
  }
  border-bottom: 0;

  .container {
    background-color: var(--p-o4);
    border: 1px solid var(--p-o3);
    border-radius: 16px;
  }

  h1 img {
    margin-left: 10px;
    vertical-align: sub;
  }

  h2 {
    font-size: 24px;
  }
}

#roadmap {
  border: 1px solid var(--p-o3);
  background:
    var(--p-o1)
    url('https://joinpeertube.org/img/peertube-logo-background.svg')
    repeat;
  margin-top: 8rem;
  padding-bottom: 4rem;

  h2 {
    background: none;
    display: block;
    font-family: Proza Libre,sans-serif;
    font-size: 34px !important;
    font-weight: 600;
    line-height: 1.2 !important;
    margin-top: 2rem;
    padding: 0;
    text-align: left;
    text-transform: none;
    transform: none;

    &:after {
      background: var(--p-o2);
      border-radius: 4px;
      content: ' ';
      display: block;
      margin-top: .5rem;
      margin-bottom: 1.25rem;
      height: 6px;
      width: 50px;
    }
  }

  .col-lg-10 {
    background: var(--p-g5);
    border: 1px solid var(--p-o3);
    border-radius: 4px;
    margin: -4rem auto 0;
    padding: 1rem 3rem 2rem;

    div {
      max-width: 700px;
      margin: 0 auto;
    }
  }
}

#who {
  border-top: none;
  margin-top: -2px;
  padding-top: 0;
  padding-bottom: 0;

  h2 {
    background: none;
    display: block;
    font-family: Proza Libre,sans-serif;
    font-size: 34px !important;
    font-weight: 600;
    line-height: 1.2 !important;
    margin: 3.5rem 0 0 0 !important;
    padding: 0;
    text-align: left;
    text-transform: none;
    transform: none;

    &:after {
      background: var(--p-o2);
      border-radius: 4px;
      content: ' ';
      display: block;
      margin-top: .5rem;
      margin-bottom: 1.25rem;
      height: 6px;
      width: 50px;
    }

    @media (min-width: 1024px) {
      margin-left: calc(100% / 3 + 10px) !important;
    }
  }

  @media (min-width: 1024px) {
    .col-lg-4 {
      margin-top: -5rem !important;
    }
  }

  h3 {
    display: inline-block;
    line-height: 1.5;
    margin-bottom: .75rem;
  }
}

/* a {
  text-decoration: underline !important;
} */

#f-sfs {
  @media (min-width: 1024px) {
    margin-top: 3.5rem;
  }

  .container {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .fa-heart {
    color: var(--p-o3);
  }

  #support h2 {
    background: none;
    color: var(--p-g1);
    display: block;
    font-family: Proza Libre,sans-serif;
    font-size: 34px !important;
    font-weight: 600;
    line-height: 1.2 !important;
    margin: 0 0 .5rem !important;
    padding: 0;
    text-align: left;
    text-transform: none;
    transform: none;
  }

  h2 img {
    height: 36px;
    margin-left: 10px;
    vertical-align: sub;
  }
}

#f-sfs-form {
  .lead,
  .list-unstyled.text-secondary, // links about frama
  #whoAreYou > div > div:nth-child(5) { // survey
    display: none;
  }
}
</style>
